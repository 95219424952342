import React from 'react';
import assemble from '../../assemble';
import { Helmet } from 'react-helmet-async';
import env from '../../../../env';
import { withRouter } from 'react-router';
import isServer from '@joybird/joystagram-components/dist/esm/isServer';
import { capitalize } from 'lodash';

const capitalizeSlug = slug =>
  slug
    .split('-')
    .map(capitalize)
    .join(' ');

const getTitle = (seoTitle, sharetitle, routeTitle, routeParams, pathname) => {
  let title = sharetitle || seoTitle || routeTitle || '';

  if (
    (seoTitle === 'PDP' ||
      routeTitle === 'Category Page' ||
      routeTitle === 'Blog Single Page') &&
    routeParams.slug?.length
  ) {
    title = capitalizeSlug(routeParams.slug);
  }
  if (pathname?.includes('customer')) {
    //routes in customer dashboard.
    const newTitle = capitalizeSlug(
      pathname.replace(/\//g, '').replace('customer', '')
    );
    return `${title} | Joybird ${newTitle}`;
  }
  if (title) {
    return `${title} | Joybird`;
  }
  return 'Joybird';
};

class Layout extends React.Component {
  get canonicalLink() {
    return `https://joybird.com${this.props.location.pathname}`;
  }

  render() {
    let noIndex = false;
    const seoconfig = this.props.route ? this.props.route.seoconfig || {} : {};
    const routeTitle = this.props.route ? this.props.route.title : '';
    if (isServer() && this.props.route.type === 'Dynamic') {
      this.props.staticContext.isDynamicRoute = true;
      const currentRoute = this.props.location.pathname;
      noIndex = !!(
        this.props.staticContext.initData?.data?.noIndexRoutes ?? []
      ).find(route => route === currentRoute);
    }
    const {
      title: seoTitle,
      description,
      sharetitle,
      sharedescription,
      shareimage,
      sharetype,
      jsonLD,
      robots_noindex,
    } = seoconfig;
    const ogDescription = sharedescription || description;
    const robotsIndexMeta = noIndex
      ? 'noindex, nofollow'
      : env.VERSION !== 'production' || robots_noindex === true
      ? 'noindex'
      : env.APP_ROBOTS_META;

    const title = getTitle(
      seoTitle,
      sharetitle,
      routeTitle,
      this.props.match.params,
      this.props.location.pathname
    );

    return (
      <React.Fragment>
        <Helmet>
          <title>{title}</title>

          {seoconfig.description && (
            <meta name="description" content={seoconfig.description} />
          )}

          {seoconfig.keywords && (
            <meta name="keywords" content={seoconfig.keywords} />
          )}

          {title && <meta name="og:title" content={title} />}

          {ogDescription && (
            <meta name="og:description" content={ogDescription} />
          )}

          {shareimage && <meta name="og:image" content={shareimage} />}

          {robotsIndexMeta && <meta name="robots" content={robotsIndexMeta} />}

          <meta name="og:type" content={sharetype || 'website'} />

          {jsonLD && (
            <script type="application/ld+json">
              {JSON.stringify(JSON.parse(jsonLD))}
            </script>
          )}

          <link href={this.canonicalLink} rel="canonical" />
        </Helmet>

        {this.assembledComponents}
      </React.Fragment>
    );
  }

  get mergedLayoutComponents() {
    if (this._mergedLayoutComponents) {
      return this._mergedLayoutComponents;
    }

    const { props } = this;
    const Wrapped = this.props.wrapped;

    this._mergedLayoutComponents = this.layout?.components.map(component => {
      if (component) {
        return component;
      }
      return <Wrapped {...props} key="route" />;
    });

    return this._mergedLayoutComponents;
  }

  get layout() {
    return this.variant?.layout;
  }

  get variant() {
    //debug('variant', this.props.variant);

    return this.props.variant;
  }

  get assembledComponents() {
    return this._assembledComponents
      ? this._assembledComponents
      : (this._assembledComponents = assemble(this.mergedLayoutComponents));
  }
}

export default withRouter(Layout);
