import React from 'react';
import loadable from '@loadable/component';

function loadableHOC(componentName) {
  const LoadableComponent = loadable(
    () => import(`@joybird/joystagram-components/dist/esm/${componentName}.js`),
    { fallback: <div /> }
  );

  LoadableComponent.displayName = `${componentName}LoadableComponent`;

  const LoadableHocWrapper = props => <LoadableComponent {...props} />;
  LoadableHocWrapper.displayName = `${componentName}LoadableWrapper`;

  return LoadableHocWrapper;
}

export default loadableHOC;
