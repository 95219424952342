import axios from 'axios';
import env from '../../services/env';
import { isClient } from '../../app/globals';

const FetchResponse = isClient() ? Response : require('node-fetch').Response

const isHeaders = headers => headers.constructor?.name === 'Headers';

const createFetchHeaders = (axiosHeaders = {}) => {
  const headers = [];
  Object.entries(axiosHeaders).forEach(([name, value]) => {
    headers.push([name, value]);
  });
  return headers;
};

const createAxiosHeaders = (headers = {}) => {
  const rawHeaders = {};
  if (isHeaders(headers)) {
    headers.forEach((value, name) => {
      rawHeaders[name] = value;
    });
  } else if (Array.isArray(headers)) {
    headers.forEach(([name, value]) => {
      if (value) {
        rawHeaders[name] = value;
      }
    });
  } else {
    Object.entries(headers).forEach(([name, value]) => {
      if (value) {
        rawHeaders[name] = value;
      }
    });
  }
  return rawHeaders;
};

const getUrl = input => {
  let url;
  if (typeof input === 'string') {
    url = input;
  } else if (input?.href) {
    url = input.href;
  } else if (input?.url) {
    url = input.url;
  }
  return url;
};

/**
 * A Fetch WebAPI implementation based on the Axios client
 */
const axiosFetch = axios => async (input, init) => {
  const rawHeaders = createAxiosHeaders(init?.headers);
  const lowerCasedHeaders = {};
  Object.entries(rawHeaders).forEach(([name, value]) => {
    lowerCasedHeaders[name.toLowerCase()] = value;
  });
  if (!('content-type' in lowerCasedHeaders)) {
    lowerCasedHeaders['content-type'] = 'text/plain;charset=UTF-8';
  }
  const config = {
    url: getUrl(input),
    method: init?.method || 'GET',
    data: init?.body,
    headers: lowerCasedHeaders,
    // Force the response to an arraybuffer type. Without this, the Response
    // object will try to guess the content type and add headers that weren't in
    // the response.
    // NOTE: Don't use 'stream' because it's not supported in the browser
    responseType: 'arraybuffer',
  };
  let result;
  try {
    result = await axios.request(config);
  } catch (err) {
    if (err.response) {
      result = err.response;
    } else {
      throw err;
    }
  }
  return new FetchResponse(result.data, {
    status: result.status,
    statusText: result.statusText,
    headers: createFetchHeaders(result.headers),
  });
};

const axiosInstance = axios.create({
  baseURL: env.GRAPHQL_BASE_URL,
  withCredentials: true
});

export default axiosFetch(axiosInstance);
