import React from 'react';
import PropTypes from 'prop-types';
import assemble from '../assemble';
import Debug from 'debug';

const debug = Debug('consumer:routes');

class Route extends React.Component {

    render() {

        debug('Prototype route is rendering.', this);

        return (
            <React.Fragment>
              {this.assembledComponents}
            </React.Fragment>
        );

    }

    get variant() {

        return this.props.variant || {};

    }

    get slug() {

        return this.props.match.params.slug;

    }

    get assembledComponents() {

        return this._assembledComponents ? this._assembledComponents : this._assembledComponents = assemble(this.variant.components || [], true);

    }

}

Route.contextTypes = {
    'services': PropTypes.object,
    'router': PropTypes.any
};

export default Route;
