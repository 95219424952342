import getAlexandriaUrl from '@joybird/joystagram-components/dist/esm/getAlexandriaUrl';

const env = {};

const getGlobalEnv = name => {
  const _env_ = global._env_;

  if (!_env_) {
    return '';
  }

  return _env_[name] ?? '';
};

env.TEMP_UID = 'tuID';
env.VERSION =
  process.env.NEXT_PUBLIC_VERSION ?? getGlobalEnv('REACT_APP_VERSION');

env.GRAPHQL_BASE_URL = getAlexandriaUrl();

env.WEBSOCKET_URI =
  process.env.NEXT_PUBLIC_WEBSOCKET_URI ??
  getGlobalEnv('REACT_APP_WEBSOCKET_URI');
env.SENTRY_DSN =
  process.env.NEXT_PUBLIC_SENTRY_DSN ?? getGlobalEnv('REACT_APP_SENTRY_DSN');
env.APP_ROBOTS_META =
  process.env.NEXT_PUBLIC_ROBOTS_META ?? getGlobalEnv('REACT_APP_ROBOTS_META');

/*if (env.DEVELOPMENT) {
    env.VERSION = 'development';
} else if (env.TEST) {
    env.VERSION = 'test';
} else if (env.PRODUCTION) {
    env.VERSION = 'production';
}*/

export default Object.freeze(env);
