import React from 'react';
import Debug from 'debug';
import _ from 'lodash';
import loadableHOC from '../../routes/lib/components/hoc/loadable';
import AboveTheFoldServerRenderer from '@joybird/joystagram-components/dist/esm/AboveTheFoldServerRenderer';

const debug = Debug('consumer:routes');

const default_props = {
  config: {},
  mode: 'consumer',
};

/**
 * @TODO - This function need *a lot* of attention. I'm not sure that all of this
 * is necessary.
 */
const assemble = (components = [], isPageComponents) => {
  debug('Assembling components.');

  components = components.map(component => {
    if (React.isValidElement(component)) {
      return component;
    }

    component = _.cloneDeep(component);

    debug('component', component, React.isValidElement(component));

    _.defaults(component, {
      config: {},
    });

    component.config = _.isObject(component.config)
      ? component.config
      : JSON.parse(component.config);
    const row = {};
    row[component.component] = component;

    return row;
  });

  components.forEach((record, index) => {
    if (React.isValidElement(record)) {
      return;
    }
    const k = Object.keys(record);
    k.forEach(key => {
      const component = record[k];
      if (_.isString(component.component)) {
        const CompLoadableWrapper = loadableHOC(component.component);

        if (isPageComponents) {
          const CompAboveTheFoldWrapper = props => (
            <AboveTheFoldServerRenderer componentIndex={index}>
              <CompLoadableWrapper {...props} />
            </AboveTheFoldServerRenderer>
          );
          CompAboveTheFoldWrapper.displayName = `${component.component}AboveTheFoldWrapper`;

          component.component = CompAboveTheFoldWrapper;
        } else {
          component.component = CompLoadableWrapper;
        }
      }
    });
  });

  const arr = [];

  for (const i in components) {
    const element = components[i];

    if (React.isValidElement(element)) {
      arr.push(element);
      continue;
    }

    const values = element[Object.keys(element)[0]];

    const props = _.cloneDeep(default_props);

    props['updateFieldConfig'] = props['updateContent'] = props[
      'updateFields'
    ] = function() {};

    const working = Object.keys(element);

    if (element[working]['url']) {
      props['url'] = element[working]['url'];
    }
    props['comp_id'] = i + 1;
    props['id'] = props['key'] = i;
    props.config = values.config;

    // const CMP = element[working].component;

    let CompWithProps;

    if (_.isFunction(element[working].component)) {
      CompWithProps = React.createElement(
        element[working].component,
        props,
        null
      );

      arr.push(CompWithProps);
    } else {
      CompWithProps = React.cloneElement(
        element[working].component,
        props,
        null
      );

      arr.push(CompWithProps);
    }
  }

  return arr;
};

export default assemble;
