import React from 'react';
import Layout from '../../layout';
import loadable from '@loadable/component';

const Error404 = loadable(() =>
  import('@joybird/joystagram-components/dist/esm/Error404')
);
const ErrorFallbackPage = loadable(() =>
  import('@joybird/joystagram-components/dist/esm/ErrorFallbackPage')
);
const ConsumerHeader = loadable(() =>
  import('@joybird/joystagram-components/dist/esm/ConsumerHeader')
);
const ConsumerNewFooter = loadable(() =>
  import('@joybird/joystagram-components/dist/esm/ConsumerNewFooter')
);

function layoutHOC(WrappedComponent, route) {
  return props => {
    //In case of Error404 the routes argument is undefined
    if (!route) {
      return (
        <ErrorFallbackPage>
          <ConsumerHeader />
          <Error404 />
          <ConsumerNewFooter />
        </ErrorFallbackPage>
      );
    }
    return (
      <ErrorFallbackPage>
        <Layout {...props} wrapped={WrappedComponent} route={route} />
      </ErrorFallbackPage>
    );
  };
}

export default layoutHOC;
