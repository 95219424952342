import React from 'react';
import _ from 'lodash';
import apolloClient from '../apolloClient';
import layoutHOC from './lib/components/hoc/layout';
import variantHOC from './lib/components/hoc/variant';
import ProtoRoute from './lib/proto-route';
import requestIdleCallback from '@joybird/joystagram-components/dist/esm/requestIdleCallback';
import isServer from '@joybird/joystagram-components/dist/esm/isServer';
import { Route, Redirect } from 'react-router-dom';
import Error404 from '@joybird/joystagram-components/dist/esm/Error404';
import Debug from 'debug';
import LazyRoute from './LazyRouteComponent';
import { ROUTE_ERROR_DATA_UPDATED } from '@joybird/joystagram-components/dist/esm/Constants';
import { GET_ROUTES_QUERY } from '@joybird/joystagram-components/dist/esm/exportedQueries';

import { getFinalParamsForDynamicVariants } from '../../commons/utils';
import isClient from '@joybird/joystagram-components/dist/esm/isClient';
import { dispatchCustomEvent } from '@joybird/joystagram-components/dist/esm/useCustomEvent';
import assemble from './lib/assemble';

const debug = Debug('consumer:routes');

class Routes {
  _reactRoutes = Object.freeze([]);
  _componentCache = {};

  get layouts() {
    return this._layouts;
  }

  set layouts(value) {
    value = _.cloneDeep(value);

    value = value.map(layout => {
      layout.components = layout.components.map(component => {
        try {
          if (!component) {
            return component;
          }
          component.config = JSON.parse(component.config);
          return component;
        } catch (err) {
          console.log(
            'Error initializing component',
            component,
            'in layout',
            layout.id,
            err
          );
        }
        return null;
      });
      return layout;
    });

    debug('Setting layouts', value);

    this._layouts = value;
  }

  get routes() {
    return this._routes;
  }

  get serverQueryParams() {
    return this._queryParams;
  }

  set serverQueryParams(queryParams) {
    this._queryParams = queryParams;
  }

  get serverRouteParams() {
    return this._serverRouteParams;
  }

  set serverRouteParams(routeParams) {
    this._serverRouteParams = routeParams;
  }

  getNumOfPathVariablesInRoute(route) {
    return (route.match(/:/g) || []).length;
  }

  getNumOfNonDynamicPathsInRoute(route) {
    return route.split('/').length - this.getNumOfPathVariablesInRoute(route);
  }

  set routes(value) {
    this.setRoutesOnly(value);
    this._generateReactRoutes();
  }

  setRoutesOnly(value) {
    value = _.cloneDeep(value);

    value.sort((a, b) => {
      if (a.route.indexOf(':') > -1 && b.route.indexOf(':') === -1) {
        //If a has dynamic path and b doesn't have one, place b higher in the list
        return 1;
      }
      if (b.route.indexOf(':') > -1 && a.route.indexOf(':') === -1) {
        //If b has dynamic path and a doesn't have one, place a higher in the list
        return -1;
      }
      // Place the route with more number of fixed parts on the top.
      // ":category/:slug/product-visualization" will be placed above ":category/:slug"
      return (
        this.getNumOfNonDynamicPathsInRoute(b.route) -
        this.getNumOfNonDynamicPathsInRoute(a.route)
      );
    });
    this._routes = Object.freeze(value);
  }

  set redirectRoutes(value) {
    this._redirectRoutes = Object.freeze(value);
    this._generateReactRedirectRoutes();
  }

  set printCampaignRedirectRoutes(value) {
    this._printCampaignRedirectRoutes = Object.freeze(value);
    this._generatePrintCampaignRedirectRoutes();
  }

  get reactRoutes() {
    return this._reactRoutes;
  }

  async fetchRoutesData() {
    const resp = await apolloClient.query({
      query: GET_ROUTES_QUERY,
    });
    this.setRoutesOnly(resp.data.routes);
  }

  getComponentForRoute(
    routeKey,
    serverQueryParams,
    serverRouteParams, // Will contain params even when called from the client
    hasDynamicVariants = false,
    routeDynamicVariantKeys
  ) {
    let finalRouteKey = routeKey;
    // Generate key for route with Dynamic variant
    if (hasDynamicVariants) {
      const finalParams = isClient()
        ? serverRouteParams
        : getFinalParamsForDynamicVariants({
            srcPath: routeKey,
            serverRouteParams,
          });
      if (!_.isEmpty(finalParams)) {
        // Get dynamic key, join params if multiple available
        const paramKeys = Object.keys(finalParams);
        let paramKey =
          paramKeys.length > 1
            ? paramKeys.map(pKey => finalParams[pKey]).join('/')
            : finalParams[paramKeys[0]];

        if (paramKey.endsWith('/')) {
          paramKey = paramKey.slice(0, -1);
        }

        const dynamicVariantMatch = (routeDynamicVariantKeys || []).find(
          dynVarntKey => {
            const finalDynKey = dynVarntKey.endsWith('/')
              ? dynVarntKey.slice(0, -1)
              : dynVarntKey;
            return finalDynKey.trim() === paramKey.trim();
          }
        );
        if (dynamicVariantMatch) {
          finalRouteKey += `-${dynamicVariantMatch}`;
        }
      }
    }

    if (!this._componentCache[finalRouteKey]) {
      const route = this.routes.find(({ route }) => route === routeKey);
      const routeWithLayout = layoutHOC(ProtoRoute, route);

      const _route = {
        Component: routeWithLayout,
        path: route.route,
      };

      const Component = variantHOC(
        _route,
        serverQueryParams,
        serverRouteParams
      );

      this._componentCache[finalRouteKey] = Component;
    }

    return this._componentCache[finalRouteKey];
  }

  get reactRedirectRoutes() {
    return this._reactRedirectRoutes;
  }

  get react404RouteComponents() {
    return this._404RouteComponents;
  }

  get react500RouteComponents() {
    return this._500RouteComponents;
  }

  get reactPrintCampaignRedirectRoutes() {
    return this._reactPrintCampaignRedirectRoutes;
  }

  _normalizeRoutePath(path) {
    return path.charAt(0) === '/' ? path : '/' + path;
  }

  getRouteByPath(srcPath) {
    return _.find(this._routes, { route: srcPath });
  }

  _generateReactRoutes() {
    this._reactRoutes = this.routes
      .map(route => {
        route = _.cloneDeep(route);
        // Pass dynamic variant keys for caching
        const routeDynamicVariantKeys = route.dynamicVariants?.length
          ? route.dynamicVariants.map(dynVarnt => dynVarnt.dynamicKey)
          : null;
        return (
          <Route
            render={props => (
              <LazyRoute
                routeKey={route.route}
                serverQueryParams={this.serverQueryParams}
                childProps={props}
                serverRouteParams={this.serverRouteParams}
                hasDynamicVariants={!!route.dynamicVariants?.length}
                routeDynamicVariantKeys={routeDynamicVariantKeys}
              />
            )}
            path={this._normalizeRoutePath(route.route)}
            key={route.id}
            exact
          />
        );
      })
      .filter(Boolean);
    this._reactRoutes.push(
      <Route
        component={layoutHOC(Error404)}
        key={this._reactRoutes.length + 1}
      />
    );
    //Temporary fix for holt-collection URL - Needs to be made extendable
    this._reactRoutes.unshift(
      <Route exact key="holt-route" path="/holt-collection">
        <Redirect to="/collections/holt/" />
      </Route>
    );
    this._reactRoutes = Object.freeze(this._reactRoutes);
    this._404RouteComponents = this._assembleRouteComponents(
      'http_404_error_page'
    );
    this._500RouteComponents = this._assembleRouteComponents(
      'http_500_error_page'
    );
    dispatchCustomEvent(ROUTE_ERROR_DATA_UPDATED, {});
  }

  _assembleRouteComponents(path) {
    const route = this.routes.find(({ route }) => route === path);
    const routeComponents =
      route?.variants?.length > 0
        ? route.variants.find(({ id }) => id === route.defaultVariantId)
            .components
        : [];
    return assemble(routeComponents, true);
  }

  _generateReactRedirectRoutes() {
    this._reactRedirectRoutes = Object.freeze(
      this._redirectRoutes.map(redirect => (
        <Route
          exact
          path={this._normalizeRoutePath(redirect.from)}
          key={redirect.from}
        >
          <Redirect to={redirect.to} />
        </Route>
      ))
    );
  }

  _generatePrintCampaignRedirectRoutes() {
    this._reactPrintCampaignRedirectRoutes = Object.freeze(
      this._printCampaignRedirectRoutes.map(redirect => (
        <Route
          exact
          path={this._normalizeRoutePath(redirect.from)}
          key={redirect.from}
        >
          <Redirect to={redirect.to} />
        </Route>
      ))
    );
  }
}

const routes = new Routes();

if (isClient()) {
  window.addEventListener('load', function() {
    setTimeout(() => {
      if (!routes.routes || routes.routes.find(route => !route.variants)) {
        requestIdleCallback(() => routes.fetchRoutesData());
      }
    }, 3000);
  });
}

export default routes;
