const polyfill = [];

if (
  typeof window !== 'undefined' &&
  !('scrollBehavior' in window.document.documentElement.style)
) {
  polyfill.push(
    import(
      /* webpackChunkName: "polyfill-smooth-scroll" */ '../commons/smoothscroll'
    )
  );
}

export default polyfill;
