import { Component } from 'react';
import { withRouter } from "react-router-dom";
import { isClient } from './app/globals';
class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if ((this.props.location.pathname !== prevProps.location.pathname) && isClient()) {
      const locationState = this.props.location.state;
      if (locationState && locationState.scrollToTop === false) {
        return;
      }
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
